<template>
  <div>
      <!-- 输入和搜索区域 -->
    <div class="search-row">
        <el-button type="danger" icon="el-icon-delete" @click="clearSearchResult">清除</el-button>
        <el-button type="primary" icon="el-icon-search" @click="searchCoupon">搜索</el-button>
        <el-button type="success" icon="el-icon-edit" @click.native.prevent="editNewCoupon" :disabled="role < 300">新建优惠券</el-button>
        <el-input v-model="search.coupon_id" placeholder="搜索优惠券" clearable></el-input>
        <el-date-picker v-model="search.valid_from" placeholder="有效期开始" type="date"  class="date-picker-wide" clearable></el-date-picker>
        <el-date-picker v-model="search.valid_to" placeholder="有效期结束" type="date" class="date-picker-wide" clearable></el-date-picker>
    </div>
    <div class="table-container">
        <!-- 表格展示数据 -->
        <el-table :data="filteredDatatable" :header-cell-style="{background:'#f2f5fc', color:'#555'}" height="70vh">
            <!-- 列定义 -->
            <el-table-column type="index" label="序号" fixed/>
            <el-table-column prop="coupon_id" label="优惠券ID" flex="1"></el-table-column>
            <el-table-column prop="coupon_name" label="优惠券名称" flex="1"></el-table-column>
            <el-table-column prop="discount_type" label="折扣类型" flex="1"></el-table-column>
            <el-table-column prop="valid_from" label="有效期开始" flex="1" :formatter="row => (row.valid_from)"></el-table-column>
            <el-table-column prop="valid_to" label="有效期结束" flex="1" :formatter="row => (row.valid_to)"></el-table-column>
            <el-table-column label="仅限新客户" flex="1">
                <template slot-scope="scope">
                    {{ scope.row.new_customer_only ? '✅' : '❌' }}
                </template>
            </el-table-column>
            <el-table-column label="允许重复使用" flex="1">
                <template slot-scope="scope">
                    {{ scope.row.repeated_usage_allowed ? '✅' : '❌'}}
                </template>
            </el-table-column>
            <el-table-column prop="min_spend_requirement" label="最低消费要求" flex="1"></el-table-column>
            <el-table-column prop="max_discount" label="最大折扣" flex="1"></el-table-column>
            <el-table-column prop="discount_value" label="折扣值" flex="1"></el-table-column>

            <el-table-column prop="created_at" label="创建时间" flex="1" :formatter="row => (row.created_at)"></el-table-column>

            <el-table-column prop="operate" label="操作">
                <template slot-scope="scope">
                    <el-button size='small' type='success' icon="el-icon-edit" @click="openEditCouponWindow(scope.row)" :disabled="role < 300">编辑</el-button>
                    <!-- <el-button size='small' type='danger' icon="el-icon-delete" @click="deleteCoupon(scope.row.coupon_id)">删除</el-button> -->
                </template>
            </el-table-column>
        </el-table>
    </div>
      <!-- 分页组件 -->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>

    <!-- 弹窗编辑物品 -->

    <el-dialog title="编辑优惠券" :visible.sync="showEditDialog"  :close-on-click-modal="false">
        <el-form :model="editCoupon" :rules="rules" ref="editCouponForm">
            <el-form-item label="优惠券ID (不可重复)" prop="coupon_id">
                <el-input v-model="editCoupon.coupon_id" placeholder="(推荐格式: YYMM_XPOFF(百分比) / YYMM_XDOFF (绝对数值))" :disabled="isEditing"></el-input>
            </el-form-item>

            <el-form-item label="优惠券名称" prop="coupon_name">
                <el-input v-model="editCoupon.coupon_name" placeholder="(推荐格式: 满XX减免XX(绝对值) / 满XXN折 (百分比))"></el-input>
            </el-form-item>
            
            <el-form-item label="有效期开始" prop="valid_from">
                <el-date-picker v-model="editCoupon.valid_from" :picker-options="pickerOptionsFrom" type="date" placeholder="有效期开始" clearable :disabled="isEditing" @change="updatePickerOptions"></el-date-picker>
            </el-form-item>
            <el-form-item label="有效期结束" prop="valid_to">
                <el-date-picker v-model="editCoupon.valid_to" :picker-options="pickerOptionsTo" type="date" placeholder="有效期结束" clearable @change="updatePickerOptions"></el-date-picker>
            </el-form-item>
            <el-form-item label="仅限新客户" prop="new_customer_only">
                <el-checkbox v-model="editCoupon.new_customer_only" label="仅限新客户"></el-checkbox>
            </el-form-item>

            <el-form-item label="允许重复使用" prop="repeated_usage_allowed">
                <el-checkbox v-model="editCoupon.repeated_usage_allowed" label="允许重复使用"></el-checkbox>
            </el-form-item>

            <el-form-item label="折扣类型" prop="discount_type">
                <el-radio-group v-model="editCoupon.discount_type" :disabled="isEditing">
                    <el-radio label="percentage"></el-radio>
                    <el-radio label="value"></el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="最大折扣 (当折扣类型为百分比时, 最大的折扣绝对值)" prop="max_discount">
                <el-input v-model="editCoupon.max_discount" placeholder="最大折扣"></el-input>
            </el-form-item>

            <el-form-item label="折扣值" prop="discount_value">
                <el-input v-model="editCoupon.discount_value" placeholder="折扣值"></el-input>
            </el-form-item>

            <el-form-item label="最低消费要求" prop="min_spend_requirement">
                <el-input v-model="editCoupon.min_spend_requirement" placeholder="最低消费要求"></el-input>
            </el-form-item>

        </el-form>
        
        <span slot="footer" class="dialog-footer">
                <el-button @click="cancelEdit">取消</el-button>
                <el-button type="primary" @click="isEditing? submitUpdate(): createCoupon()">保存</el-button>
            </span>
    </el-dialog>
    </div>
</template>

<script>
import { searchCoupons, createNewCoupon, updateExistingCoupon } from '@/utils/coupons';  
import {convertToMelbourneTime , formatLocalDate} from '@/utils/time_utils';

export default {
  name: "CouponsTable",
  data() {
      return {
        users: [],
        query: '', // 新增:用户输入的查询
        originalDatatable: [],
       limit: 10,
        page: 1,
        total: 0,
        role: null,
        currentUser: null, 
        search: {
            coupon_id: '',
            valid_from: null,
            valid_to: null
        },
        roleMap: {
                    '超级管理员': 400, 
                    '管理员': 300,
                    '经理': 200,
                    '客服': 100,
                // 其他角色...
            },   
        editCoupon: {
                coupon_id: '',
                coupon_name: '',
                valid_from: '',
                valid_to: '',
                new_customer_only: false,
                repeated_usage_allowed: false,
                discount_type: '',
                max_discount: 9999,
                discount_value: 0,
                min_spend_requirement: 0
        },
        // 共享编辑部分, isEditing = false 为创建订单， isEditing = true 为编辑订单
        showEditDialog: false,
        isEditing: false,
        rules: {
                coupon_id: [
                    { required: true, message: '*优惠券ID不能为空', trigger: 'blur' }
                ],
                coupon_name: [
                    { required: true, message: '*优惠券名称不能为空', trigger: 'blur' }
                ],
                new_customer_only: [
                    { required: true, message: '*仅限新客户不能为空', trigger: 'blur' }
                ],
                repeated_usage_allowed: [
                    { required: true, message: '*允许重复使用不能为空', trigger: 'blur' },
                    { validator: this.validateRepeatedUsageAllowed, trigger: 'blur' }
    
                ],
                discount_type: [
                    { required: true, message: '*折扣类型不能为空', trigger: 'blur' }
                ],
                max_discount : [
                    { required: true, message: '*最大折扣不能为空', trigger: 'blur' },
                    { validator: this.validateMaxDiscount, trigger: 'blur' }
                ],
                discount_value: [
                    { required: true, message: '*折扣值不能为空', trigger: 'blur' },
                    { validator: this.validateDiscountValue, trigger: 'blur' }
                ],
                min_spend_requirement: [
                    { required: true, message: '*最低消费要求不能为空', trigger: 'blur' }
                ]
            }    
      };
    },

  methods: {
    formatLocalDate,
    editNewCoupon() {
        this.editCoupon = {
            coupon_id: '',
            coupon_name: '',
            valid_from: new Date(),
            valid_to: '',
            new_customer_only: false,
            repeated_usage_allowed: false,
            discount_type: 'percentage',  // 设置默认值为 'percentage' 或 'value'，根据实际需求
            max_discount: 0,
            discount_value: 0,
            min_spend_requirement: 0
        };
        this.showEditDialog = true;
        this.isEditing = false;
    },

    async createCoupon() {
        // 首先进行表单验证
        this.$refs['editCouponForm'].validate(async (valid) => {
            if (!valid) {
                this.$message.error('请检查输入后重试');
                return false;
            }

            // 复制一份 editCoupon，因为下面要修改它
            let newCoupon = JSON.parse(JSON.stringify(this.editCoupon));

            // 在提交之前将日期转换为本地时间的0点0分
            newCoupon.valid_from = convertToMelbourneTime(newCoupon.valid_from);
            newCoupon.valid_to = convertToMelbourneTime(newCoupon.valid_to);

            // 如果discount_type是value，那么max_discount就是discount_value
            if (newCoupon.discount_type === 'value') {
                newCoupon.max_discount = newCoupon.discount_value;
            }

            console.log("创建优惠券:", newCoupon);

            try {
                const response = await createNewCoupon({ coupon_id: newCoupon.coupon_id, coupon_object: newCoupon });
                if (response.status === 200) {
                    this.$message.success(`成功创建优惠券${newCoupon.coupon_id}`);
                }
                this.clearCouponEditing();
                this.showEditDialog = false;
                await this.loadData(); // 重新加载数据
            } catch (error) {
                    this.$message.error(error.message); 
            }
        });
    },

    async searchCoupon() {
        const searchCopy = JSON.parse(JSON.stringify(this.search));
        try{
            const response = await searchCoupons(
                {
                    coupon_id: searchCopy.coupon_id,
                    valid_from: searchCopy.valid_from,
                    valid_to: searchCopy.valid_to,
                    limit : this.limit,
                    page: this.page
                }
            )
            if (response.status === 200) {
                this.originalDatatable = response.data;
                this.total = response.data.length === 0 ? 0 : response.data.length;
                this.$message.success(`搜索成功, 返回 ${this.total} 条结果`)
            }
        }
        catch(error){
            this.$message({
                    message: `搜索失败: ${error.message}`,
                    type: 'error'
            });
            console.error("搜索优惠券出错:", error.message);
        }
        

    },
    clearSearchResult() {
        this.search = {
            coupon_id: '',
            valid_from: null,
            valid_to: null
        },
        this.page = 1;
        this.loadData();
    }, 

    clearCouponEditing() {
        this.editCoupon = {
            coupon_id: '',
            valid_from: '',
            valid_to: '',
            new_customer_only: false,
            repeated_usage_allowed: false,
            discount_type: '',
            max_discount: 0,
            discount_value: 0,
            min_spend_requirement: 0
        };
        // this.selectedDateRange = []; // 确保日期范围被清空
    },
  
    async loadData() {
        try {
            const response = await searchCoupons({ limit: this.limit, page: this.page } );
            this.originalDatatable = response.data;
            this.total = response.data.length === 0 ? 0 : parseInt(response.data[0].number_of_rows);
        } catch (error) {
            console.error("加载数据出错:", error.message);
        }
    },

    // 打开弹窗编辑物品
    openEditCouponWindow(current_coupon_row) {
        this.clearCouponEditing();
        this.editCoupon = JSON.parse(JSON.stringify(current_coupon_row));
        // 如果折扣类型是 'value'，将 max_discount 设置为 discount_value
        if (this.editCoupon.discount_type === 'value') {
            this.editCoupon.max_discount = this.editCoupon.discount_value;
        }
        this.showEditDialog = true;
        this.isEditing = true;
    },
    // 
    cancelEdit() {
    // 取消按钮的点击事件
        // this.selectedDateRange = []; // 重置日期选择
        this.clearCouponEditing();
        this.showEditDialog = false;
        this.isEditing = false;
    },
    // 提交更新
    async submitUpdate() {
        // 验证表单
        this.$refs['editCouponForm'].validate(async (valid) => {
            if (!valid) {
            this.$message.error('请检查输入后重试。');
            return false;
            }

            // 克隆 editCoupon 对象
            let editCoupon = JSON.parse(JSON.stringify(this.editCoupon));

            // 在提交之前将日期转换为本地时间的0点0分
            editCoupon.valid_from = convertToMelbourneTime(editCoupon.valid_from);
            editCoupon.valid_to = convertToMelbourneTime(editCoupon.valid_to);


            // 如果 discount_type 是 'percentage'，检查 discount_value 是否在 0 和 100 之间
            if (editCoupon.discount_type === 'percentage') {
            if (editCoupon.discount_value < 0 || editCoupon.discount_value > 100) {
                this.$message.error('百分比类型的折扣值必须在 0 和 100 之间。');
                return;
            }
            } else if (editCoupon.discount_type === 'value') {
            // 如果 discount_type 是 'value'，检查 max_discount 是否等于 discount_value
            if (editCoupon.max_discount !== editCoupon.discount_value) {
                this.$message.error('数值类型的最大折扣必须等于折扣值。');
                return;
            }
            }

            console.log("更新优惠券:", editCoupon);

            try {
            // 在提交前进行深拷贝，以确保修改的是副本而不是直接修改了原始数据
            const response = await updateExistingCoupon({
                coupon_id: editCoupon.coupon_id,
                coupon_object: JSON.parse(JSON.stringify(editCoupon)),
            });

            if (response.status === 200) {
                this.$message.success("成功更新优惠券");
                this.showEditDialog = false;
                // 重新加载数据
                this.clearCouponEditing();
                this.loadData();
            }
            } catch (error) {
            this.$message.error("更新优惠券出错:", error.message);
            }
        });
    },


    validateDiscountValue(rule, value, callback) {
        if (this.editCoupon.discount_type === 'percentage') {
            if (value <= 0 || value > 100) {
                callback(new Error('折扣值必须在大于0并且到100之间'));
            } else {
                callback();
            }
        } else if (this.editCoupon.discount_type === 'value') {
            if (value <= 0 ) {
                callback(new Error('折扣值必须大于0'));
            }
            // 当折扣类型为 'value' 时，检查 max_discount 与 discount_value 是否相等
            if (this.editCoupon.max_discount !== this.editCoupon.discount_value) {
                callback(new Error("当折扣类型为 'value' 时, 最大折扣与折扣值必须相等"));
            } else {
                callback();
            }
        } else {
            callback();
        }
    },

    validateMaxDiscount(rule, value, callback) {
        if (value <= 0) {
            callback(new Error('最大折扣必须大于0'));
        } else {
            callback();
        }
    },
    validateRepeatedUsageAllowed(rule, value, callback) {
        {
        // Check the condition: if new_customer_only is true, repeated_usage_allowed should be false
        if (this.editCoupon.new_customer_only && value) {
            callback(new Error('当仅限新客户时，不允许重复使用'));
        }   else {
            callback();
            }
        }
    },

    initPickerOptions() {
            this.pickerOptionsFrom = {
                disabledDate: (time) => {
                    if (this.editCoupon.valid_to) {
                        return time.getTime() > new Date(this.editCoupon.valid_to).getTime();
                    }
                    return false;
                },
            };
            this.pickerOptionsTo = {
                disabledDate: (time) => {
                    if (this.editCoupon.valid_from) {
                        return time.getTime() < new Date(this.editCoupon.valid_from).getTime();
                    }
                    return false;
                },
            };
        },

    // ... 其他方法 ...


    updatePickerOptions() {
        this.initPickerOptions(); // 移除此处的更新，确保只在组件加载时设置一次
    },
    handleSizeChange(val) {
        this.limit = val;
        this.page = 1; // Reset to first page when page size changes
        this.loadData();
    },
    handleCurrentChange(val) {
        this.page = val;
        this.loadData();
    }
  },
    beforeMount() {
        // this.updatePickerOptions();
        this.loadData();
    },
    created() {
        // Get role from localStorage when the component is created
        this.role = localStorage.getItem('role');
        this.currentUser = localStorage.getItem('user_name');
        this.initPickerOptions();
    },

    watch: {
            search: {
                handler() {
                    // 检查 search 对象的所有属性是否都为空
                    const isEmpty = Object.values(this.search).every(value => value === '');
                    if (isEmpty) {
                        this.loadData(); // 如果所有属性都为空，调用 getload 方法
                    }
                },
                deep: true // 深度观察 search 对象的所有属性
            }
    }, 
    computed: {
        filteredDatatable() {
            let result = this.originalDatatable;

            // First, filter by search strings
            Object.keys(this.search).forEach(key => {
                if (this.search[key] && typeof this.search[key] === 'string') {
                    let searchString = this.search[key].replace('*', '.*');
                    let regex = new RegExp('^' + searchString, 'i');
                    result = result.filter(item => regex.test(String(item[key])));
                }
            });

            // Then, filter by date range
            if (this.search.valid_from || this.search.valid_to) {
                result = result.filter(item => {
                    let valid_from = this.search.valid_from ? convertToMelbourneTime(this.search.valid_from) : null;
                    let valid_to = this.search.valid_to ? convertToMelbourneTime(this.search.valid_to) : null;
                    let itemValidFrom = convertToMelbourneTime(item.valid_from);
                    let itemValidTo = convertToMelbourneTime(item.valid_to);

                    if (valid_from && itemValidFrom < valid_from) return false;
                    if (valid_to && itemValidTo > valid_to) return false;

                    return true;
                });
            }

            return result;
        }


    },      
}
</script>

<style scoped>
.search-row {
    display: flex;
    flex-wrap: nowrap; /* 阻止元素换行 */
    align-items: center;
    gap: 10px; /* 为元素添加间隙 */
    margin-bottom: 20px;
    max-width: 100%; /* 限制宽度为屏幕的一半 */
}

.el-select, .el-date-picker {
  flex-grow: 1;
  width: 200px;
}
.item-description-select {
    width: 100%; /* 或者你想要的任何宽度 */
}

.table-container {
    max-height: 70vh;  /* 你可以根据需要调整这个值 */
    overflow-x: auto; /* 显示横向滚动条 */
    overflow-y: auto; 
}

.el-table-column {
  text-align: center;
}

.el-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .search-row {
    flex-direction: column;
  }

  .el-input, .el-select, .el-date-picker {
    max-width: none;
  }
}

</style>