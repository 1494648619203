<template>
  <div>
  <!-- 输入和搜索区域 -->
  <div class="search-fields">
      <el-button type="success" icon="el-icon-edit" @click="editNewOrder(editingOrder)">新建订单</el-button>
      <el-button type="danger" icon="el-icon-delete" @click="clearSearchResult">清除搜索</el-button>
      <el-button type="primary" icon="el-icon-search" @click="searchOrder">搜索</el-button>
      <el-input v-model.lazy="search.order_id" placeholder="搜索订单ID" clearable></el-input>
      <el-input v-model.lazy="search.alias_order_id" placeholder="搜索运单号" clearable></el-input>
      <el-input v-model.lazy="search.user_id" placeholder="搜索用户ID" clearable ></el-input>
      <el-date-picker v-model.lazy="search.created_from" placeholder="创建日期从" type="date"  class="date-picker-wide" clearable></el-date-picker>
      <el-date-picker v-model.lazy="search.created_to" placeholder="创建日期到" type="date" class="date-picker-wide" clearable></el-date-picker>
  </div>


      <!-- 表格展示数据 -->
      <div class="table-container">
          <el-table :data="filteredDatatable" :header-cell-style="{background:'#f2f5fc', color:'#555'}" height="70vh" border>
          <el-table-column type="index" label="序号" fixed/>
          <el-table-column prop="order_id" label="订单ID" flex="1"></el-table-column>
          <el-table-column prop="alias_order_id" label="运单号" fixed></el-table-column>
          <el-table-column prop="user_id" label="用户ID" fixed></el-table-column>
          <el-table-column prop="length" label="长(m)" flex="1"></el-table-column>
          <el-table-column prop="width" label="宽(m)" flex="1"></el-table-column>
          <el-table-column prop="height" label="高(m)" flex="1"></el-table-column>
          <el-table-column prop="total_weight" label="总重量(kg)" flex="1"></el-table-column>
          <el-table-column prop="total_volume" label="总体积(m3)" flex="1"></el-table-column>
          <el-table-column prop="number_of_boxes" label="合箱数" flex="1"></el-table-column>
          <el-table-column prop="price" label="价格" flex="1"></el-table-column>
          <el-table-column prop="total_declared_value" label="申报总价值" flex="1"></el-table-column>
          <el-table-column prop="order_status" label="订单状态" flex="1"></el-table-column>
          <el-table-column prop="payment_method" label="付款方式" flex="1"></el-table-column>
          <el-table-column prop="shipping_method" label="运输方式" flex="1"></el-table-column>
          <el-table-column prop="delivery_method" label="送货方式" flex="1"></el-table-column>
          <el-table-column prop="updated_at" label="更新时间" flex="1" :formatter="row => (row.updated_at)"></el-table-column>
          <el-table-column prop="created_at" label="创建时间" flex="1" :formatter="row => (row.created_at)"></el-table-column>
          <el-table-column prop="sender_address" label="寄件人地址" flex="1"></el-table-column>
          <el-table-column prop="sender_mobile" label="寄件人电话" flex="1"></el-table-column>
          <el-table-column prop="receiver" label="收件人" flex="1"></el-table-column>
          <el-table-column prop="receiver_address" label="收件人地址" flex="1"></el-table-column>
          <!--  -->
          <el-table-column prop="receiver_state" label="收件人州" flex="1"></el-table-column>
          <el-table-column prop="receiver_postcode" label="收件人邮编" flex="1"></el-table-column>
          <el-table-column prop="receiver_mobile" label="收件人手机" flex="1"></el-table-column>
          <el-table-column prop="receiver_email" label="收件人邮箱" flex="1"></el-table-column>
          <el-table-column prop="is_insurance_required" label="是否需要保险" flex="1">
              <template slot-scope="{ row }">
                  {{ row.is_insurance_required === true ? '✅' : '❌' }}
              </template>
          </el-table-column>
          <el-table-column prop="is_unpacked_required" label="是否需要拆包" flex="1">
              <template slot-scope="{ row }">
                  {{ row.is_unpacked_required === true ? '✅' : '❌' }}
              </template>
          </el-table-column>
          <el-table-column prop="additional_services" label="附加服务" flex="1"></el-table-column>
          <el-table-column prop="shipment_batch" label="航运批次" flex="1"></el-table-column>
          <el-table-column prop="shipment_deadline" label="截单日期" flex="1"></el-table-column>

          <el-table-column type="warning" round label="编辑订单" flex="1">
          <template slot-scope="scope">
              <el-dropdown>
                  <el-button type="primary" size="small">
                      操作选项<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item @click.native="editOrder(scope.row)">编辑订单信息</el-dropdown-item>
                      <el-dropdown-item @click.native="openInsertItemDialog(scope.row)">添加物品到订单</el-dropdown-item>
                      <el-dropdown-item @click.native="openRemoveItemDialog(scope.row)">从订单删除物品</el-dropdown-item>
                      <el-dropdown-item @click.native="confirmDeletion({orderId: scope.row.order_id, userId: scope.row.user_id, aliasOrderId: scope.row.alias_order_id})" style="color: red;" :disabled="role < 200">取消订单</el-dropdown-item>
                  </el-dropdown-menu>
              </el-dropdown>
          </template>
          </el-table-column>
          <el-table-column label="确认订单" flex="1">
              <template slot-scope="scope">
                  <el-button  type="success" round size="small" icon="el-icon-check" @click="() => confirmOrders({orderId: scope.row.order_id, userId: scope.row.user_id, aliasOrderId: scope.row.alias_order_id})"></el-button>
              </template>
          </el-table-column>
          </el-table>
          </div>
          <!-- 分页组件 -->
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="limit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
          </el-pagination>
          <!-- 编辑订单信息 -->    
          <el-dialog title="编辑订单信息" :visible.sync="showEditOrderDialog" :close-on-click-modal="false">
              <el-row>
                  <el-col :span="8">
                      <el-input
                              v-if="!isEditing"
                              v-model="searchUserId"
                              placeholder="搜索用户ID获取可以合包的物品"
                              clearable
                      ></el-input>
                  </el-col>
                  <el-col :span="2">
                      <el-button
                              v-if="!isEditing"
                              type="primary"
                              @click="onSearchButtonClick"
                              >
                                      搜索
                      </el-button>
                  </el-col>
                  <el-col :span="12">
                      <el-select
                              v-if="!isEditing"
                              v-model="selectedItems"
                              multiple
                              :placeholder="insertableItemsData.length === 0 ? '没有可以合包的物品' : '选择可以合包的物品'"
                              >
                              <el-option
                                      v-for="item in insertableItemsData"
                                      :key="item.item_id"
                                      :label="`物流订单: ${item.tracking_id}, 类目: ${item.item_category}, 描述: ${item.item_description}, 体积: ${item.volume}, 重量: ${item.weight}, 长: ${item.length}, 宽: ${item.width}, 高: ${item.height}, 入库时间: ${(item.scanned_at)}`"
                                      :value="item.item_id"
                              ></el-option>
                      </el-select>
                  </el-col>
              </el-row>

              <el-form :model="editingOrder" :rules="rules" ref="editingOrderForm">
                    <el-form-item label="寄件人" prop="sender">
                        <el-input v-model="editingOrder.sender"></el-input>
                    </el-form-item>

                    <el-form-item label="寄件人国家" prop="sender_country">
                    <CountryList v-model="editingOrder.sender_country" />
                    </el-form-item>

                    <el-form-item label="寄件人省/州" prop="sender_state">
                        <el-input v-model="editingOrder.sender_state"></el-input>
                    </el-form-item>

                    <el-form-item label="寄件人地址" prop="sender_address">
                        <el-input v-model="editingOrder.sender_address"></el-input>
                    </el-form-item>

                    <!-- <el-form-item label="寄件人城市" prop="sender_city">
                    <el-input v-model="editingOrder.sender_city"></el-input>
                    </el-form-item>

                    <el-form-item label="寄件人城区" prop="sender_suburb">
                    <el-input v-model="editingOrder.sender_suburb"></el-input>
                    </el-form-item>

                    <el-form-item label="寄件人街道名" prop="sender_street_name">
                    <el-input v-model="editingOrder.sender_street_name"></el-input>
                    </el-form-item>

                    <el-form-item label="寄件人街道号" prop="sender_street_number">
                    <el-input v-model="editingOrder.sender_street_number"></el-input>
                    </el-form-item>
        
                    <el-form-item label="寄件人单元号" pro="sender_unit_number">
                        <el-input v-model="editingOrder.sender_unit_number"></el-input>
                    </el-form-item> -->
                    
                    <el-form-item label="寄件人邮编" prop="sender_postcode">
                        <el-input v-model="editingOrder.sender_postcode" @input="editingOrder.sender_postcode = String(editingOrder.sender_postcode).replace(/\s/g, '')"></el-input>
                    </el-form-item>

                    <el-form-item label="寄件人邮箱地址" prop="sender_email">
                        <el-input v-model="editingOrder.sender_email"></el-input>
                    </el-form-item>

                    <el-form-item label="收件人" prop="receiver">
                        <el-input v-model="editingOrder.receiver"></el-input>
                    </el-form-item>

                    <el-form-item label="收件人地址" prop="receiver_address">
                        <el-input v-model="editingOrder.receiver_address"></el-input>
                    </el-form-item>

                    <el-form-item label="收件人国家" prop="receiver_country">
                    <CountryList v-model="editingOrder.receiver_country" />
                    </el-form-item>

                    <!-- <el-form-item label="收件人单元号" prop="receiver_unit_number">
                        <el-input v-model="editingOrder.receiver_unit_number"></el-input>
                    </el-form-item>

                    <el-form-item label="收件人街道号" prop="receiver_street_number">
                        <el-input v-model="editingOrder.receiver_street_number"></el-input>
                    </el-form-item>

                    <el-form-item label="收件人街道名" prop="receiver_street_name">
                        <el-input v-model="editingOrder.receiver_street_name"></el-input>
                    </el-form-item>

                    <el-form-item label="收件人Suburb" prop="receiver_suburb"> 
                        <el-input v-model="editingOrder.receiver_suburb" @input="editingOrder.receiver_suburb = editingOrder.receiver_suburb.replace(/\d/g, '')"></el-input>
                    </el-form-item> -->

                    <el-form-item label="收件人州" prop="receiver_state"> 
                        <el-input v-model="editingOrder.receiver_state" @input="editingOrder.receiver_state = editingOrder.receiver_state.replace(/\d/g, '')"></el-input>
                    </el-form-item>

                    <el-form-item label="收件人邮编" prop="receiver_postcode">
                        <el-input v-model="editingOrder.receiver_postcode" @input="editingOrder.receiver_postcode = String(editingOrder.receiver_postcode).replace(/\s/g, '')"></el-input>
                    </el-form-item>


                    <el-form-item label="收件人手机" prop="receiver_mobile">
                        <el-input v-model="editingOrder.receiver_mobile" @input="editingOrder.receiver_mobile = editingOrder.receiver_mobile.replace(/\D/g, '')"></el-input>
                    </el-form-item>

                    <el-form-item label="收件人邮箱地址" prop="receiver_email">
                        <el-input v-model="editingOrder.receiver_email"></el-input>
                    </el-form-item>

                    <el-form-item label="运输方式" prop="shipping_method">
                        <el-radio-group v-model="editingOrder.shipping_method">
                            <el-radio v-for="method in shippingMethods" :key="method" :label="method" :value="method"></el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="送货方式" prop="delivery_method">
                        <el-radio-group v-model="editingOrder.delivery_method">
                            <el-radio v-for="method in deliveryMethods" :key="method" :label="method" :value="method"></el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="支付方式" prop="payment_method">
                        <el-radio-group v-model="editingOrder.payment_method">
                            <el-radio v-for="method in paymentMethods" :key="method" :label="method" :value="method"></el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="总长度(m)" prop="length">
                        <el-input v-model="editingOrder.length"></el-input>
                    </el-form-item>

                    <el-form-item label="总宽度(m)" prop="width">
                        <el-input v-model="editingOrder.width"></el-input>
                    </el-form-item>

                    <el-form-item label="总高度(m)" prop="height">
                        <el-input v-model="editingOrder.height"></el-input>
                    </el-form-item>

                    <el-form-item label="总价格" prop="price">
                        <el-input v-if="isEditing" v-model="editingOrder.price"></el-input>
                        <el-input v-else v-model="editingOrder.price"></el-input>
                    </el-form-item>

                    <el-form-item label="总体积(m3)" prop="total_volume">
                        <el-input v-model="editingOrder.total_volume"></el-input>
                    </el-form-item>

                    <el-form-item label="总价格" prop="price">
                        <el-input v-if="isEditing" v-model="editingOrder.price"></el-input>
                        <el-input v-else v-model="editingOrder.price"></el-input>
                    </el-form-item>

                    <el-form-item label="总申报价值" prop="total_declared_value">
                        <el-input v-model="editingOrder.total_declared_value"></el-input>
                    </el-form-item>

                    <el-form-item label="合箱数" prop="number_of_boxes">
                        <el-input v-model="editingOrder.number_of_boxes"></el-input>
                    </el-form-item>

                    <el-form-item label="是否需要保险" prop="is_insurance_required">
                        <el-radio-group v-model="editingOrder.is_insurance_required">
                            <el-radio label="是" :value=true></el-radio>
                            <el-radio label="否" :value=false></el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="是否需要拆包" prop="is_unpacked_required">
                        <el-radio-group v-model="editingOrder.is_unpacked_required">
                            <el-radio label="是" :value=true></el-radio>
                            <el-radio label="否" :value=false></el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="航运批次" prop="shipment_batch">
                        <el-input v-model="editingOrder.shipment_batch"></el-input>
                    </el-form-item>
                    <el-form-item label="截单日期" prop="shipment_deadline">
                        <el-input v-model="editingOrder.shipment_deadline"></el-input>
                    </el-form-item>
                    <el-form-item label="附加服务" prop="additional_services">
                    <el-select v-model="editingOrder.additional_services" multiple placeholder="请选择">
                        <el-option label="合箱打包" value="合箱打包"></el-option>
                        <el-option label="精密仪器" value="精密仪器"></el-option>
                        <el-option label="防水防潮" value="防水防潮"></el-option>
                        <el-option label="绕膜扎带" value="绕膜扎带"></el-option>
                        <el-option label="防撞木框" value="防撞木框"></el-option>
                        <el-option label="家具相关服务" value="家具相关服务"></el-option>
                        <el-option label="其他(联系客服)" value="其他(联系客服)"></el-option>
                        <el-option label="保险" value="保险"></el-option>
                    </el-select>
                </el-form-item>

                </el-form>
          <span slot="footer" class="dialog-footer">
              <el-button @click="cancelEdit">取消</el-button>
              <el-button type="primary" @click="isEditing ? updateOrderDetails() : createNewOrders()">保存</el-button>
          </span>
      </el-dialog>
      

      <el-dialog :visible.sync="showInsertItemDialog" title="该用户可添加物品" :close-on-click-modal="false">
          <div v-if="insertableItemsData.length > 0">
              <el-table :data="insertableItemsData" style="width: 100%" border>
                  <el-table-column prop="item_id" label="物品ID"></el-table-column>
                  <el-table-column prop="scanned_at" label="入库时间"></el-table-column>
                  <el-table-column prop="user_id" label="用户ID"></el-table-column>
                  <el-table-column prop="tracking_id" label="Tracking ID"></el-table-column>
                  <el-table-column prop="item_category" label="物品类目"></el-table-column>
                  <el-table-column prop="item_description" label="物品描述"></el-table-column>
                  <el-table-column prop="item_status" label="物品状态"></el-table-column>
                  <el-table-column prop="is_sensitve_good" label="是否敏感"
                        :formatter="row => row.is_sensitve_good ? '✅' : '❌'">
                  </el-table-column>
                  <el-table-column prop="declared_value" label="申报价值"></el-table-column>
                  <el-table-column prop="weight" label="重量(kg)"></el-table-column>
                  <el-table-column prop="volume" label="体积(m3)"></el-table-column>
                  <el-table-column prop="length" label="长度(m)"></el-table-column>
                  <el-table-column prop="width" label="宽度(m)"></el-table-column>
                  <el-table-column prop="height" label="高度(m)"></el-table-column>
                  <el-table-column label="操作">
                      <template slot-scope="scope">
                        <el-button icon="el-icon-plus" @click="insertItemToOrder({orderId: selectedOrderId, itemId:scope.row.item_id, alias_order_id: selectedAliasOrderId})"></el-button>
                      </template>
                  </el-table-column>
              </el-table>
          </div>
          <div v-else>
              <p>没有可以添加的物品</p>
          </div>
      </el-dialog>

  
      <el-dialog :visible.sync="showRemoveItemDialog" title="该订单已有物品" :close-on-click-modal="false">
          <el-table :data="removableItemsData" style="width: 100%" border>
              <el-table-column prop="item_id" label="物品ID"></el-table-column>
              <el-table-column prop="user_id" label="用户ID"></el-table-column>
              <el-table-column prop="tracking_id" label="Tracking ID"></el-table-column>
              <el-table-column prop="order_status" label="订单状态"></el-table-column>
              <el-table-column prop="item_category" label="物品类目"></el-table-column>
              <el-table-column prop="item_description" label="物品描述"></el-table-column>
              <el-table-column prop="declared_value" label="申报价值"></el-table-column>
              <el-table-column prop="weight" label="重量(kg)"></el-table-column>
              <el-table-column prop="volume" label="体积(m3)"></el-table-column>
              <el-table-column prop="length" label="长度(m)"></el-table-column>
              <el-table-column prop="width" label="宽度(m)"></el-table-column>
              <el-table-column prop="height" label="高度(m)"></el-table-column>
              <el-table-column label="操作">
                  <template slot-scope="scope">
                      <el-button v-if="removableItemsData.length > 1" icon="el-icon-delete" @click="removeItemFromOrder(selectedOrderId, scope.row.item_id)"></el-button>
                  </template>
              </el-table-column>
          </el-table>
      </el-dialog>

    </div>
  </template>
  
  <script>
  // import axios from 'axios';
  import instance from '@/utils/axios.config';
  import { changeOrderStatus, calculatePrice, createNewOrder, searchOrders, concateOrderAddress } from '../../utils/orders';    
  import { formatLocalDate, convertToMelbourneTime} from '@/utils/time_utils';
  import { splitAddress } from '@/utils/address_utils';
  import CountryList from './CountryList.vue';
  import { getRole } from '@/utils/auth';
  export default {
    name: "PackedOrderTable",
    components: {
            CountryList
      },
    data() {
      return {
          showInsertItemDialog: false,
          showRemoveItemDialog: false,
          selectedOrderId:  null,
          selectedAliasOrderId:  null,
          insertableItemsData: [],
          removableItemsData: [],
          ordersData: [],
          shippingMethods: [
              process.env.VUE_APP_SHIPPING_METHOD_AIR,
              process.env.VUE_APP_SHIPPING_METHOD_SEA,
              process.env.VUE_APP_SHIPPING_METHOD_SEA_SENSITIVE,
              process.env.VUE_APP_SHIPPING_METHOD_AIR_SENSITIVE
          ],
          deliveryMethods: [
                process.env.VUE_APP_DELIVERY_METHOD_DOOR_TO_DOOR,
                process.env.VUE_APP_DELIVERY_METHOD_PICKUP
          ],
          paymentMethods: [
              process.env.VUE_APP_PAYMENT_METHOD_WECHAT,
              process.env.VUE_APP_PAYMENT_METHOD_BANK,
              process.env.VUE_APP_PAYMENT_METHOD_ALIPAY,
              process.env.VUE_APP_PAYMENT_METHOD_B2B
          ],
          // 分页组件
         limit: 10, // 默认的每页显示的条目数
          page: 1, // 默认的当前页码
          total: 0, // Default record size
          order_status: process.env.VUE_APP_ORDER_STATUS_PACKED,
          role: null,
          editingOrder: {
            length: null,
            width: null,
            height: null,
            total_weight: null,
            total_volume: null,
            total_declared_value: null,
            price: null,
            number_of_boxes: null,
            receiver:  null,
            receiver_unit_number:  null,
            receiver_street_number:  null,
            receiver_street_name:  null,
            receiver_suburb:  null,
            receiver_state:  null,
            receiver_postcode:  null,
            receiver_country:  null,
            receiver_mobile:  null,
            receiver_email:  null,
            shipping_method:  null,
            delivery_method:  null,
            payment_method:  null,
            sender:  null,
            sender_address:  null, // added 
            sender_unit_number:  null,
            sender_street_number:  null,
            sender_street_name:  null,
            sender_city:  null,
            sender_mobile:  null,
            sender_suburb:  null, // added
            sender_state:  null, // added
            sender_postcode:  null, // added
            sender_country:  null, // added
            sender_email:  null, // added
            is_insurance_required:  null,
            is_unpacked_required:  null,
            additional_services:  null,
            shipment_batch:  null,
            shipment_deadline:  null,
          },
          search: {
                  order_id:  null,
                  user_id:  null,
                  order_status: process.env.VUE_APP_ORDER_STATUS_PACKED,
                  created_from:  null,
                  created_to:  null,
                  limit: this.limit,
                  page: this.page
          },
          showEditOrderDialog: false,
          showCreateDialog: false,
          // 共享编辑部分, isEditing = false 为创建订单， isEditing = true 为编辑订单
          isEditing: false,
          // 制单部分
          searchUserId:  null,
          selectedItems: [],
          previousSearchUserId: null,
          rules: {
                sender: [
                    { required: true, message: '*寄件人姓名不能为空', trigger: 'blur' },
                    // { validator: this.validateNoDigits, message: '*寄件人姓名不能包含数字', trigger: 'blur' },
                ],
                sender_country: [
                    { required: true, message: '*寄件人国家不能为空', trigger: 'blur' },
                ],
                sender_state: [
                    { required: true, message: '*寄件人省份/州不能为空', trigger: 'blur' },
                    { validator: this.validateNoDigits, message: '*寄件人省份/州不能包含数字', trigger: 'blur' },
                ],
                sender_address: [
                    { required: true, message: '*寄件人详细地址不能为空', trigger: 'blur' },
                ],
                // sender_city: [
                //     { required: true, message: '*寄件人城市名称不能为空', trigger: 'blur' },
                //     { validator: this.validateNoDigits, message: '*寄件人城市名称不能包含数字', trigger: 'blur' },
                // ],
                // sender_suburb: [
                //     { required: true, message: '*寄件人城区名称不能为空', trigger: 'blur' },
                //     { validator: this.validateNoDigits, message: '*寄件人城区名称不能包含数字', trigger: 'blur' },
                // ],
                // sender_street_name: [
                //     { required: true, message: '*寄件人街道名不能为空', trigger: 'blur' },
                //     { validator: this.validateNoDigits, message: '*寄件人街道名不能包含数字', trigger: 'blur' },
                // ],
                // sender_street_number: [
                //     { required: true, message: '*寄件人街道号不能为空', trigger: 'blur' },
                // ],
                // sender_unit_number: [
                //     // { required: true, message: '*寄件人单元号不能为空', trigger: 'blur' },
                //     { validator: this.validateNoDigits, message: '*寄件人单元号不能为空', trigger: 'blur' },
                // ],
                sender_mobile: [
                    { required: true, message: '*寄件人手机不能为空', trigger: 'blur' },
                    // { pattern: /^(13[0-9]|14[5-9]|15[0-3,5-9]|16[6]|17[0-8]|18[0-9]|19[8,9])\d{8}$/, message: '*寄件人手机应为13、14、15、16、17、18、19开头的11位数字', trigger: 'blur' },
                ],
                sender_postcode: [
                    // { required: true, message: '*寄件人邮编不能为空', trigger: 'blur' },
                    { pattern: /^\d+$/, message: '*寄件人邮编只能是数字', trigger: 'blur' },
                ],
                sender_email: [
                    // { required: true, message: '*电子邮件不能为空', trigger: 'blur' },
                    { type: 'email', message: '*无效的电子邮件地址', trigger: 'blur' },
                ],
                receiver: [
                    { required: true, message: '*收件人姓名不能为空', trigger: 'blur' },
                    { validator: this.validateNoDigits, message: '*收件人姓名不能包含数字', trigger: 'blur' },
                ],
                receiver_address: [
                    { required: true, message: '*收件人详细地址不能为空', trigger: 'blur' },
                ],
                // receiver_suburb: [
                //     { required: true, message: '*收件人Suburb名称不能为空', trigger: 'blur' },
                //     { validator: this.validateNoDigits, message: '*收件人Suburb名称不能包含数字', trigger: 'blur' },
                // ],
                receiver_country: [
                    { required: true, message: '*收件人国家不能为空', trigger: 'blur' },
                ],
                // receiver_street_name: [
                //     { required: true, message: '*收件人街道名不能为空', trigger: 'blur' },
                //     { validator: this.validateNoDigits, message: '*收件人街道名不能包含数字', trigger: 'blur' },
                // ],
                // receiver_street_number: [
                //     { required: true, message: '*收件人街道号不能为空', trigger: 'blur' },
                // ],
                // receiver_unit_number: [
                //     // { required: true, message: '*收件人单元号不能为空', trigger: 'blur' },
                // ],
                receiver_mobile: [
                    { required: true, message: '*收件人手机不能为空', trigger: 'blur' },
                ],
                receiver_postcode: [
                    { required: true, message: '*邮编不能为空', trigger: 'blur' },
                    { validator: this.validatePostcode, trigger: 'blur' },
                ],
                receiver_email: [
                    // { required: true, message: '*电子邮件不能为空', trigger: 'blur' },
                    { type: 'email', message: '*无效的电子邮件地址', trigger: 'blur' },
                ],    
                shipping_method: [
                    { required: true, message: '*必须选择运输方式', trigger: 'blur' },
                ],
                delivery_method: [
                    { required: true, message: '*必须选择送货方式', trigger: 'blur' },
                ],
                payment_method: [
                    { required: true, message: '*必须选择支付方式', trigger: 'blur' },
                ],
                total_weight: [
                    { required: true, message: '*总重量不能为空', trigger: 'blur' },
                    { pattern: /^\d*\.?\d+$/, message: '*总重量必须为正数且不能为 0', trigger: 'blur' },
                ],
                total_volume: [
                    { required: true, message: '*总体积不能为空', trigger: 'blur' },
                    { pattern: /^\d*\.?\d+$/, message: '*总体积必须为正数且不能为 0', trigger: 'blur' },
                ],
                total_declared_value: [
                    { required: true, message: '*总申报价值', trigger: 'blur' },
                    { pattern: /^\d*\.?\d+$/, message: '*总申报价值必须为正数且不能为 0', trigger: 'blur' },
                ],
                price: [
                    { required: true, message: '*总价格不能为空', trigger: 'blur' },
                    { pattern: /^\d*\.?\d+$/, message: '*总价格必须为正数且不能为 0', trigger: 'blur' },
                ],
                number_of_boxes: [
                    { required: true, message: '*合箱数不能为空', trigger: 'blur' },
                    { pattern: /^\d+$/, message: '*合箱数必须为正整数且不能为 0', trigger: 'blur' },
                ],
                is_insurance_required: [
                    { required: true, message: '*必须选择是否需要保险', trigger: 'change' },
                ],                            
                is_unpacked_required: [
                    { required: true, message: '*必须选择是否需要拆包', trigger: 'change' },
                ],
                shipment_batch: [
                    { required: true, message: '*航运批次不能为空', trigger: 'blur' },
                ],     
                shipment_deadline: [
                    { required: true, message: '*截单日期不能为空', trigger: 'blur' },
                ],     
            },

          };
    },
    async created() {
        // Get role from localStorage when the component is created
        const { role, currentUser } = getRole();
        this.role = role;
        this.currentUser = currentUser;
        this.loadData();
      },
      watch: {
          // 动态更新总重量，总体积，总价格
          selectedItems(newSelectedItems) {
              let selectedItemsData = newSelectedItems.map(itemId => this.insertableItemsData.find(item => item.item_id === itemId));
              this.editingOrder.total_weight = selectedItemsData.reduce((total, item) => total + (Number(item.weight) || 0), 0);
              this.editingOrder.total_volume = selectedItemsData.reduce((total, item) => total + (Number(item.volume) || 0), 0);
              this.editingOrder.total_declared_value = selectedItemsData.reduce((total, item) => total + (Number(item.declared_value) || 0), 0);
              this.editingOrder.price = calculatePrice(this.editingOrder.total_weight, this.editingOrder.total_volume, this.editingOrder.shipping_method);  
          },
          totalPrice(newPrice) {
            this.editingOrder.price = newPrice;
        },
          search: {
                handler() {
                    // 检查 search 对象的所有属性是否都为空
                    const isEmpty = Object.values(this.search).every(value => value === '');
                    if (isEmpty) {
                    this.loadData(); // 如果所有属性都为空，调用 getload 方法
                    }
                },
                deep: true // 深度观察 search 对象的所有属性
            }
      },       
    methods: {
      formatLocalDate,
      convertToMelbourneTime,
      splitAddress,
      getRole,
      async loadData() {
          try{
              const response = await searchOrders({ page: this.page, limit: this.limit, order_status: this.order_status});
            //   console.log("response",response)    
              if (response.status === 200) {
                  this.ordersData = response.data;
                  this.total = response.data.length === 0 ? 0 : parseInt(response.data[0].number_of_rows);
              }

          }catch (error) {
              this.$message.error(`获取订单失败! ${error.response.data.error}`);
          }   
      
      },

      openInsertItemDialog(row) {
          this.showInsertItemDialog = true;
          this.selectedOrderId = row.order_id; // 保存选中的订单ID
          this.selectedAliasOrderId = row.alias_order_id; // 保存选中的运单号
          this.fetchInsertableItemsForUser(row.user_id);
        },

      openRemoveItemDialog(row) {
          this.showRemoveItemDialog = true;
          this.selectedOrderId = row.order_id; // 保存选中的订单ID
          this.fetchRemovableItemsFromOrder(row.order_id);
        },

    async fetchRemovableItemsFromOrder(order_id) {
        console.log(`order_status: ${process.env.VUE_APP_ITEM_STATUS_MERGED}, ${process.env.VUE_APP_ITEM_STATUS_MERGING}`)
        try {
            const response = await instance.get(`/orders/order_items/order_id/${order_id}`, {
                params: {
                    item_status: `${process.env.VUE_APP_ITEM_STATUS_MERGED}, ${process.env.VUE_APP_ITEM_STATUS_MERGING}`
                }
            });
            this.removableItemsData = response.data.data;
        } catch (error) {
            console.error("Error fetching items for user:", error);
        }
    },
      
      async fetchInsertableItemsForUser(userId) {
          try {
              const response = await instance.get(`/orders/insertable-item/user_id/${userId}`, {});
  
              if (response.status === 200) {
                  this.$message.success(`获取物品成功!`);
                  this.insertableItemsData = response.data.data;
                  console.log("insertableItemsData",this.insertableItemsData)
              }
          } catch (error) {
              this.$message.error(`获取物品失败! ${error.response.data.error}`);
              console.error("Error fetching items for user:", error);
          }
      },

  
      async insertItemToOrder({orderId, itemId, alias_order_id}) {
          try {
              // 构建查询参数
              const params = new URLSearchParams();
              params.append('item_id', itemId);
              params.append('alias_order_id', alias_order_id);

              // 发送请求
              const response = await instance.post(`/orders/insert-item-to-order/order_id/${orderId}`, null, {
              params
              });

              // 处理响应
              if (response.status === 200) {
                  this.$message.success(`添加物品成功!`);
              }
              
              // 删除相对应的数据
              this.insertableItemsData = this.insertableItemsData.filter(item => item.item_id !== itemId);

              // 刷新获得更新后的价格, 重量和体积
              this.loadData();

          } catch (error) {
              console.error("Error adding item to order:", error);
              // 错误处理逻辑
              this.$message.error(`添加物品失败! ${error.response.data.error}`);
          }
      },

      async removeItemFromOrder(orderId, itemId) {
          try {
              // 发送请求
              const response = await instance.delete(`/orders/remove-item-from-order/order_id/${orderId}?item_id=${itemId}`, null, {
                  headers: {
                      'Content-Type': 'application/json'
                  }
              });

              // 处理响应
              if (response.status === 200) {
                  this.$message.success(`删除物品成功!`);
              }
              
              // 删除相对应的数据
              this.removableItemsData = this.removableItemsData.filter(item => item.item_id !== itemId);

              // 刷新获得更新后的价格, 重量和体积
              this.loadData();
              
          } catch (error) {
              console.error("Error removing item from order:", error);
              // 错误处理逻辑
              this.$message.error(`删除物品失败! ${error.response.data.error}`);
          }
      },
      async searchOrder() {
          try {
              // 创建一个搜索参数对象，包含所有可能的搜索参数
              let searchParams = JSON.parse(JSON.stringify(this.search));

              // 移除空的搜索参数
              Object.keys(searchParams).forEach(key => {
                  if (searchParams[key] === '') {
                      delete searchParams[key];
                  }
              });
              console.log("searchParams",searchParams)

              // 使用 searchOrders 函数发送请求
              const response = await searchOrders({
                    ... searchParams,                    
                    limit : this.limit,
                    page: this.page
                });
                if (response.status === 200) {
                    this.ordersData = response.data;
                    this.total = response.data.length === 0 ? 0 : response.data.length;
                    this.$message.success(`搜索订单成功! 返回${this.total} 条结果`);
                    if (this.total === 0) {
                        this.page = 1; // 如果没有数据，重置页码为 1
                    }
                }
          } catch (error) {
              this.$message.error(`搜索订单失败! ${error.response.data.error}`);
          }
      },
      
      clearSearchResult(){
          this.search = {
              order_id:  null,
              alias_order_id:  null,
              user_id:  null,
              order_status: process.env.VUE_APP_ORDER_STATUS_PACKED,
              created_from:  null,
              created_to:  null,
              limit: this.limit,
              page: this.page
          };

          // 重新加载数据
          this.page = 1;
          this.loadData();
      },
  
      clearEditingOrder(){
            this.editingOrder =  {
                total_weight: null,
                length: null,
                width: null,
                height: null,
                total_volume: null,
                total_declared_value: null,
                price: null,
                number_of_boxes: null,
                receiver:  null,
                receiver_address:  null,
                // receiver_unit_number:  null,
                // receiver_street_number:  null,
                // receiver_street_name:  null,
                // receiver_suburb:  null,
                receiver_state:  null,
                receiver_postcode:  null,
                receiver_country:  null,
                receiver_mobile:  null,
                receiver_email:  null,
                shipping_method:  null,
                delivery_method:  null,
                payment_method:  null,
                sender:  null,
                sender_address:  null,
                // sender_unit_number:  null,
                // sender_street_number:  null,
                // sender_street_name:  null,
                // sender_city:  null,
                sender_mobile:  null,
                // sender_suburb:  null, // added
                sender_state:  null, // added
                sender_postcode:  null, // added
                sender_country: null,
                sender_email:  null, // added
                is_insurance_required:  null,
                is_unpacked_required:  null,
                additional_services:  null,
                shipment_batch: null,
                shipment_deadline: null
            },
            this.searchUserId = '';
            this.selectedItems = [];
        },
      confirmDeletion({orderId, userId, aliasOrderId}) {
            // Show a confirmation dialog
            this.$confirm('确定要取消这个订单吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
            .then(async () => {
                // If the user confirmed the deletion, delete the order
                try {
                    const response = await changeOrderStatus({ orderId, userId, aliasOrderId, old_status: this.order_status, new_status: process.env.VUE_APP_ORDER_STATUS_CANCELLED })
                    if (response.status === 200) {
                        this.$message.success(`订单取消成功!`);
                        this.loadData();
                        this.total = this.ordersData.length;
                    }
                } catch (error) {
                    this.$message.error(`订单取消失败! ${error.message}`);
                }
            })
            .catch(() => {
                // If the user cancelled the deletion, do nothing
                this.$message.info(`操作取消!`);
            });
        },

      confirmOrders( {orderId, userId, aliasOrderId}) {
          // Show a confirmation dialog
          this.$confirm('最终确认这个订单吗?请检查价格以及收件人信息的正确性', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
          })
          .then(async () => {
              // If the user confirmed the deletion, delete the order
              try {
                const response = await changeOrderStatus({orderId, userId, aliasOrderId, old_status: this.order_status, new_status: process.env.VUE_APP_ORDER_STATUS_CONFIRMED})
                  if (response.status === 200) {
                      this.$message.success(`订单确认成功!`);
                      this.loadData();
                      this.total = this.ordersData.length;
                  }
              } catch (error) {
                  this.$message.error(`订单确认失败! ${error.message}`);
              }
          })
          .catch(() => {
              // If the user cancelled the deletion, do nothing
              this.$message.info(`操作取消!`);
          });
      },            
 

      async updateOrderDetails() {
            // create a new object to avoid modifying the original one
            const modified_order_details = JSON.parse(JSON.stringify(this.editingOrder));
            this.$refs['editingOrderForm'].validate(async (valid) => {

                if (!valid) {
                    this.$message.error('请检查输入后重试');
                    return false;
                }

                try {
                    const orderId = modified_order_details.order_id;
                    const userId = modified_order_details.user_id; // ensure this value is available
                    let receiver_address_info  = {
                        address: modified_order_details.receiver_address,
                        state: modified_order_details.receiver_state,
                        postcode: modified_order_details.receiver_postcode,
                        country: modified_order_details.receiver_country
                    };
                    let sender_address_info = {
                        address: modified_order_details.sender_address,
                        state: modified_order_details.sender_state,
                        postcode: modified_order_details.sender_postcode,
                        country: modified_order_details.sender_country
                    };
                    // concat address
                    const senderAddress = concateOrderAddress(sender_address_info);
                    const receiverAddress = concateOrderAddress(receiver_address_info);

                    const payload = {
                        sender: modified_order_details.sender,
                        sender_address: senderAddress,
                        // sender_unit_number: modified_order_details.sender_unit_number,
                        // sender_street_number: modified_order_details.sender_street_number,
                        // sender_street_name: modified_order_details.sender_street_name,
                        // sender_suburb: modified_order_details.sender_suburb,
                        // sender_city: modified_order_details.sender_city,
                        sender_state: modified_order_details.sender_state,
                        sender_postcode: modified_order_details.sender_postcode,
                        sender_mobile: modified_order_details.sender_mobile,
                        sender_email: modified_order_details.sender_email,
                        receiver: modified_order_details.receiver,
                        receiver_address: receiverAddress,
                        // receiver_unit_number: modified_order_details.receiver_unit_number,
                        // receiver_street_number: modified_order_details.receiver_street_number,
                        // receiver_street_name: modified_order_details.receiver_street_name,
                        // receiver_suburb: modified_order_details.receiver_suburb,
                        receiver_state: modified_order_details.receiver_state,
                        receiver_postcode: modified_order_details.receiver_postcode,
                        receiver_country: modified_order_details.receiver_country,
                        receiver_mobile: modified_order_details.receiver_mobile,
                        receiver_email: modified_order_details.receiver_email,
                        shipping_method: modified_order_details.shipping_method,
                        delivery_method: modified_order_details.delivery_method,
                        payment_method: modified_order_details.payment_method,
                        length: modified_order_details.length,
                        width: modified_order_details.width,
                        height: modified_order_details.height,
                        total_volume: modified_order_details.total_volume,
                        total_weight: modified_order_details.total_weight,
                        total_declared_value: modified_order_details.total_declared_value,
                        price: modified_order_details.price,
                        number_of_boxes: modified_order_details.number_of_boxes,
                        is_insurance_required: modified_order_details.is_insurance_required === '否' ? false : true,
                        is_unpacked_required: modified_order_details.is_unpacked_required === '否' ? false : true,
                        additional_services: JSON.stringify(modified_order_details.additional_services),
                        shipment_batch: modified_order_details.shipment_batch,
                        shipment_deadline: modified_order_details.shipment_deadline
                    };
                    console.log("payload",payload)

                    const response =  await instance.post(`/orders/update-order-details/order_id/${orderId}?user_id=${userId}`, payload, {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });
                    if (response.status === 200) {
                        this.$message.success(`订单更新成功!`);
                        this.showEditOrderDialog = false;
                        // 重新刷新
                        this.loadData();
                        // 清除搜索框
                        this.clearEditingOrder()
                    } 
                //   this.searchOrders(); // Refresh the orders list
                } catch (error) {
                    if (error.response && error.response.data && error.response.data.error) {
                        this.$message.error(`更新订单失败: ${error.response.data.error}`);
                    } else {
                        this.$message.error(error);
                    }
                }
            });
        },

      async createNewOrders() {
            // create a new object to avoid modifying the original one
            const new_order_details = JSON.parse(JSON.stringify(this.editingOrder));
            console.log("new_order_details",new_order_details)
            const new_selected_items = Object.assign([], this.selectedItems);
            const user_id = this.searchUserId;

            if (new_selected_items.length === 0) {
                this.$message.error('请添加物品后重试');
                return false;
            }

            if (user_id === '') {
                this.$message.error('请填写用户ID后重试');
                return false;
            }

            this.$refs['editingOrderForm'].validate(async (valid) => {

                if (!valid) {
                    this.$message.error('请检查输入后重试');
                    return false;
                }

                let sender_address_info  = {
                    address: new_order_details.sender_address,
                    sender: new_order_details.sender,
                    state: new_order_details.sender_state,
                    postcode: new_order_details.sender_postcode,
                    country: new_order_details.sender_country,
                    mobile: new_order_details.sender_mobile,
                    email: new_order_details.sender_email
                };
                let receiver_address_info = {
                    address: new_order_details.receiver_address,
                    receiver: new_order_details.receiver,
                    state: new_order_details.receiver_state,
                    postcode: new_order_details.receiver_postcode,
                    country: new_order_details.receiver_country,
                    mobile: new_order_details.receiver_mobile,
                    email: new_order_details.receiver_email,
                    shipping_method: new_order_details.shipping_method
                };
     
                sender_address_info.sender_address = concateOrderAddress(sender_address_info);
                receiver_address_info.receiver_address = concateOrderAddress(receiver_address_info);

                console.log("sender_address_info",sender_address_info)
                console.log("receiver_address_info",receiver_address_info)

                const orderObject = {
                    user_id: user_id,
                    order_items: {
                        scanned_item_ids: new_selected_items
                    },
                    sender_info: sender_address_info,
                    receiver_info: receiver_address_info,
                    dimensions: {
                        total_weight: new_order_details.total_weight,
                        total_volume: new_order_details.total_volume,
                        number_of_boxes: new_order_details.number_of_boxes,
                        total_declared_value: new_order_details.total_declared_value
                    },
                    payments: {
                        price: new_order_details.price,
                        payment_method: new_order_details.payment_method,
                        delivery_method: new_order_details.delivery_method,
                        is_insurance_required: new_order_details.is_insurance_required === '否' ? false : true,
                        is_unpacked_required: new_order_details.is_unpacked_required === '否' ? false : true,
                        additional_services: JSON.stringify(new_order_details.additional_services),
                        shipment_batch: new_order_details.shipment_batch,
                        shipment_deadline: new_order_details.shipment_deadline
                    },
                    
                };

                console.log(JSON.stringify(orderObject))
            
                try {
                    const response = await createNewOrder(orderObject);
                    if (response.status === 200) {
                        this.$message.success(`订单${response.order_id}创建成功!`);
                        this.showEditOrderDialog = false;
                        // 重新刷新
                        this.loadData();
                        // 清除搜索框
                        this.clearEditingOrder()
                    }
                } catch (error) {
                    if (error.response && error.response.data && error.response.data.error) {
                        this.$message.error(`订单创建失败: ${error.response.data.error}`);
                    } else {
                        this.$message.error(error);
                    }
                }
            });
        },


      cancelEdit() {
          this.showEditOrderDialog = false;
          this.searchUserId = '';
          this.selectedItems = [];
          this.clearEditingOrder();
      },

      editOrder(row) {
          let editingOrderCopy = JSON.parse(JSON.stringify(row));
          const receiver_country = splitAddress({address: editingOrderCopy.receiver_address});
          const sender_country = splitAddress({address: editingOrderCopy.sender_address});
          editingOrderCopy = { ...editingOrderCopy, receiver_country, sender_country };

          this.editingOrder = editingOrderCopy;
          this.editingOrder.is_insurance_required = editingOrderCopy.is_insurance_required === true ? '是' : '否';
          this.editingOrder.is_unpacked_required = editingOrderCopy.is_unpacked_required === true ? '是' : '否';
          this.editingOrder.additional_services = editingOrderCopy.additional_services ? JSON.parse(editingOrderCopy.additional_services) : null;
          this.showEditOrderDialog = true;
          this.isEditing = true;
      },
    // Other methods
    editNewOrder() {
            this.editingOrder = {
                length: null,
                width: null,
                height: null,
                total_weight: null,
                total_volume: null,
                price: null,
                total_declared_value: null,
                number_of_boxes: null,
                sender: '小羽',
                sender_address:'上海市浦东新区自由贸易区试验区金沪路1222号507单元',
                sender_state: '上海市',
                // sender_city: '上海市',
                // sender_suburb: '浦东新区',
                // sender_street_name: '自由贸易区试验区金沪路',
                // sender_street_number: '1222号',
                // sender_unit_number: '507单元',
                sender_postcode: '511400',
                sender_country: 'China',
                sender_mobile: '13817413452',
                sender_email: 'daintylogistic@gmail.com',
                receiver: '测试收件人',
                receiver_address: '123 Elizabeth St, Melbourne',
                // receiver_unit_number: '1601',
                // receiver_street_number: '123',
                // receiver_street_name: 'Elizabeth St',
                // receiver_suburb: 'Melbourne',
                receiver_state: 'VIC',
                receiver_postcode: '3000',
                receiver_country: 'Australia',
                receiver_mobile: '0414986333',
                receiver_email: 'a@gmail.com',
                shipping_method: '',
                delivery_method: '送货上门',
                payment_method: '微信',
                is_insurance_required:  null,
                is_unpacked_required:  null,
                additional_services:  null,
                shipment_batch: null,
                shipment_deadline: null
            };
            this.showEditOrderDialog = true;
            this.isEditing = false;
        },
      
      validateNoDigits(rule, value, callback) {
          if (/\d/.test(value)) {
              callback(new Error(rule.message));
          } else {
              callback();
          }
      },

      validatePostcode(rule, value, callback) {
          if (value && (/[a-zA-Z]/.test(value))) {
            callback(new Error('*邮编不能包含字母'));
          } else {
          callback();
          }
      },

      onSearchButtonClick() {
          
          if (this.searchUserId !== this.previousSearchUserId) {
              this.selectedItems = [];
              this.insertableItemsData = [];
              this.previousSearchUserId = this.searchUserId;
          }

          this.fetchInsertableItemsForUser(this.searchUserId);
          console.log("insertableItemsData",this.insertableItemsData)
          this.insertableItemsData = this.insertableItemsData.filter(insertableItem => 
              !this.selectedItems.includes(insertableItem)
          );
          // if (this.insertableItemsData.length > 0) {
          //     this.showInsertItemDialog = true;
          // }
      },

      async handleSizeChange(val) {
          this.limit = val;
          this.page = 1; // Reset to first page when page size changes
          this.loadData();
      },
      async handleCurrentChange(val) {
          const maxPage = Math.ceil(this.total / this.limit);
              if (val > maxPage) {
                  this.page = maxPage > 0 ? maxPage : 1; // 重置到最大页码，如果没有数据则为 1
                  return;
              }
          this.page = val;
          // this.searchOrders();
          this.loadData();
      }
    },
    beforeMount() {
      // this.searchOrders();
    },
    computed: {
        filteredDatatable() {
            let result = this.ordersData;

            // First, filter by search strings
            Object.keys(this.search).forEach(key => {
                if (this.search[key] && typeof this.search[key] === 'string') {
                    let searchString = this.search[key].replace('*', '.*');
                    let regex = new RegExp('^' + searchString, 'i');
                    result = result.filter(order => regex.test(String(order[key])));
                }
            });

            // Then, filter by date range
            if (this.search.created_from || this.search.created_to) {
                result = result.filter(order => {
                    let created_from = this.search.created_from ? convertToMelbourneTime(this.search.created_from) : null;
                    let created_to = this.search.created_to ? convertToMelbourneTime(this.search.created_to) : null;
                    let orderCreateFrom = convertToMelbourneTime(order.created_at);
                    let orderCreateTo = convertToMelbourneTime(order.created_at);

                    if (created_from && orderCreateFrom < created_from) return false;
                    if (created_to && orderCreateTo > created_to) return false;

                    return true;
                });
            }

            return result;
        },
        totalPrice() {
         // Replace this with your own calculation
            return calculatePrice(this.editingOrder.total_weight, this.editingOrder.total_volume, this.editingOrder.shipping_method)
        },
    },

  }


  </script>
  
  <style scoped>
  .search-fields {
      display: flex;
      flex-wrap: nowrap; /* 阻止元素换行 */
      align-items: center;
      gap: 10px; /* 为元素添加间隙 */
      margin-bottom: 20px;
      max-width: 100%; /* 限制宽度为屏幕的一半 */
  }
  .el-select, .date-picker-wide {
      width: 50%; /* 限制最大宽度 */
  }

  .table-container{
      max-height: 80vh; /* Adjust this value according to your needs */
      overflow-x: auto; /* 显示横向滚动条 */
      overflow-y: auto; 
  }

  .el-pagination {
      text-align: center;
  }
  </style>