import { render, staticRenderFns } from "./UnpaidOrdersTable.vue?vue&type=template&id=78abb832&scoped=true"
import script from "./UnpaidOrdersTable.vue?vue&type=script&lang=js"
export * from "./UnpaidOrdersTable.vue?vue&type=script&lang=js"
import style0 from "./UnpaidOrdersTable.vue?vue&type=style&index=0&id=78abb832&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78abb832",
  null
  
)

export default component.exports