<template>
    <div>
      <div class = "search-row">
      <el-button type="success" clearable icon="el-icon-edit" @click="showInsertModel = true">入库新物品</el-button>
      <el-button type="danger"  clearable icon="el-icon-delete" @click="clearSearchResult">清除搜索</el-button>
      <el-button type="primary" @click="searchScannedItem" icon="el-icon-search">搜索</el-button>
      <el-select v-model.lazy="search.is_sensitive_good" clearable placeholder="敏感物品" class="item-status-select" >
        <el-option label="是" value="true"></el-option>
        <el-option label="否" value="false"></el-option>
      </el-select>
      <el-select v-model.lazy="search.is_online" clearable placeholder="网购寄件" class="item-status-select" >
        <el-option label="是" value="true"></el-option>
        <el-option label="否" value="false"></el-option>
      </el-select>
      <el-select v-model.lazy="search.is_pickup_required" clearable placeholder="上门收件" class="item-status-select" >
        <el-option label="是" value="true"></el-option>
        <el-option label="否" value="false"></el-option>
      </el-select>
      <el-select v-model.lazy="search.item_status" clearable placeholder="搜索状态" class="item-status-select" >
        <el-option label="已入库" value="已入库"></el-option>
        <el-option label="待合箱" value="待合箱"></el-option>
        <el-option label="已合箱" value="已合箱"></el-option>
        <el-option label="已退回" value="已退回"></el-option>
      </el-select>
      <el-input v-model.lazy="search.user_id" clearable placeholder="搜索用户ID"></el-input>
      <el-input v-model.lazy="search.tracking_id" clearable placeholder="搜索快递单号"></el-input>
      <el-input v-model.lazy="search.item_id" clearable placeholder="搜索物品ID"></el-input>
      <el-date-picker v-model="search.scanned_from" placeholder="入库时间从" type="date"  class="date-picker-wide" clearable></el-date-picker>
      <el-date-picker v-model="search.scanned_to" placeholder="入库时间到" type="date" class="date-picker-wide" clearable></el-date-picker>

      </div>

      <el-dialog :visible.sync="showInsertModel" title="添加新物品" :close-on-click-model="false">
         <!-- 搜索 Tracking ID 的输入框 -->
        <el-row>
          <el-col :span="6">
            <el-input v-model="newItemSearch.user_id" placeholder="搜索用户ID" clearable></el-input>
          </el-col>
          <el-col :span="6">
            <el-input v-model="newItemSearch.tracking_id" placeholder="搜索追踪ID" clearable></el-input>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" @click="searchScannableItem()" icon="el-icon-search">搜索</el-button>
          </el-col>
        </el-row>

          <!-- 展示搜索结果的下拉框 -->
          <el-select  v-model="newItem.tracking_id" :placeholder="scannableItems.length === 0 ? '没有可以入库的物品' : '选择可以入库的物品'" clearable>
            <el-option v-for="item in scannableItems" 
                  :key="item.user_input_items_id" 
                  :label="`追踪ID: ${item.tracking_id}, 类目: ${item.item_category}, 描述: ${item.item_description}, 更新时间: ${(item.updated_at)}, 用户输入物品ID: ${item.user_input_items_id}, 申报价值: ${item.declared_value}`" 
                  :value="item.tracking_id">
            </el-option>
        </el-select>

        <!-- 在这里放置一个表单，用于填写新物品的信息 -->
        <el-form :model="newItem" :rules="rules" ref="newItemForm" >
          <el-form-item label="高(m)" prop="height">
            <el-input v-model="newItem.height"></el-input>
          </el-form-item>
          <el-form-item label="宽(m)" prop="width">
            <el-input v-model="newItem.width"></el-input>
          </el-form-item>
          <el-form-item label="长(m)" prop="length">
            <el-input v-model="newItem.length"></el-input>
          </el-form-item>
          <el-form-item label="体积(m3)" prop="volume">
            <el-input v-model="newItem.volume"></el-input>
          </el-form-item>
          <el-form-item label="重量(kg)" prop="weight">
            <el-input v-model="newItem.weight"></el-input>
          </el-form-item>
          <el-form-item label="whs_id" prop="whs_id">
            <el-input v-model="newItem.whs_id"></el-input>
          </el-form-item>
          <el-form-item label="机械码" prop="machine_id">
            <el-input v-model="newItem.machine_id"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="item_status">
            <el-select v-model="newItem.item_status" placeholder="选择状态">
              <el-option label="已入库" value="已入库"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer" :close-on-click-model="false">
            <el-button @click="closeInsertModel">取消</el-button>
            <el-button type="primary" @click="insertNewItem">保存</el-button>
        </span>
        
    </el-dialog>
    <div class="table-container">
      <el-table :data="filteredDatatable"
          :header-cell-style="{background:'#f2f5fc', color:'#555'}" height="70vh" border>
          <el-table-column type="index" label="序号" fixed/>
            <el-table-column prop="item_id" label="物品ID" flex="1">
              <template slot-scope="scope">
                  {{ scope.row.item_id }}
              </template>
            </el-table-column>
            
            <el-table-column prop="user_id" label="用户ID" fixed>
              <template slot-scope="scope">
                  {{ scope.row.user_id }}
              </template>
            </el-table-column>
            <el-table-column prop="tracking_id" label="快递单号" fixed>
              <template slot-scope="scope">
                  {{ scope.row.tracking_id }}
              </template>
              </el-table-column>
              <el-table-column prop="item_description" label="物品描述" flex="1">
              <template slot-scope="scope">
                  {{ scope.row.item_description }}
              </template>
              </el-table-column>
              <el-table-column prop="item_category" label="物品类目" flex="1"></el-table-column>
            <el-table-column prop="height" label="高(m)" flex="1">
              <template slot-scope="scope">
                  {{ scope.row.height }}
            </template>
              </el-table-column>
            <el-table-column prop="width" label="宽(m)" flex="1">
              <template slot-scope="scope">
                  {{ scope.row.width }}
            </template>
            </el-table-column>
            <el-table-column prop="length" label="长(m)" flex="1">
              <template slot-scope="scope">
                  {{ scope.row.length }}
              </template>
              </el-table-column>
              <el-table-column prop="volume" label="体积(m3)" flex="1">
              <template slot-scope="scope">
                  {{ scope.row.volume }}
              </template>
              </el-table-column>
              <el-table-column prop="weight" label="重量(kg)" flex="1">
              <template slot-scope="scope">
                  {{ scope.row.weight }}
              </template>
              </el-table-column>
              <el-table-column prop="declared_value" label="申报价值" flex="1">
              <template slot-scope="scope">
                  {{ scope.row.declared_value }}
              </template>
              </el-table-column>
              <el-table-column prop="whs_id" label="whs_id" flex="1">
              <template slot-scope="scope">
                  {{ scope.row.whs_id }}
              </template>
              </el-table-column>
              <el-table-column prop="machine_id" label="机械码" flex="1">
              <template slot-scope="scope">
                  {{ scope.row.machine_id }}
              </template>
              </el-table-column>
              <el-table-column prop="is_sensitive_good" label="是否敏感物品" flex="1">
                <template slot-scope="scope">
                    {{ scope.row.is_sensitive_good === true ? '✅' : '❌' }}
                </template>
              </el-table-column>
              <el-table-column prop="is_online" label="是否网购" flex="1">
                <template slot-scope="scope">
                    {{ scope.row.is_online === true ? '✅' : '❌' }}
                </template>
              </el-table-column>
              <el-table-column prop="is_pickup_required" label="是否上门收件" flex="1">
                <template slot-scope="scope">
                    {{ scope.row.is_pickup_required === true ? '✅' : '❌' }}
                </template>
              </el-table-column>
              <el-table-column prop="pickup_address" label="上门收件地址" flex="1">
              <template slot-scope="scope">
                  {{ scope.row.pickup_address }}
              </template>
             </el-table-column>
              <el-table-column prop="scanned_at" label="入库时间" >
              <template slot-scope="scope">
                  {{ (scope.row.scanned_at) }}
              </template>
              </el-table-column>
              <el-table-column prop="item_status" label="状态" flex="1">
              <template slot-scope="scope">
                  {{ scope.row.item_status }}
              </template>
              </el-table-column>
              <el-table-column label="操作" width="180">
                <template slot-scope="scope">
                  <el-button icon="el-icon-edit" type="success" size="small" @click="editScannedItem(scope.row)">编辑</el-button>
                  <el-button size='small' type='danger' icon="el-icon-delete" @click="deleteScannedItem(scope.row.item_id)" :disabled="role < 200">删除</el-button>
                </template>
              </el-table-column>
        </el-table>

    </div>

  <el-dialog :visible.sync="showEditModel" title="编辑物品" :close-on-click-model="false">
  <el-form :model="editingItem" :rules="rules" ref="editingItemForm">
    <el-form-item label="高(m)" prop="height">
      <el-input v-model="editingItem.height"></el-input>
    </el-form-item>
    <el-form-item label="宽(m)" prop="width">
      <el-input v-model="editingItem.width"></el-input>
    </el-form-item>
    <el-form-item label="长(m)" prop="length">
      <el-input v-model="editingItem.length"></el-input>
    </el-form-item>
    <el-form-item label="体积(m3)" prop="volume">
      <el-input v-model="editingItem.volume"></el-input>
    </el-form-item>
    <el-form-item label="重量(kg)" prop="weight">
      <el-input v-model="editingItem.weight"></el-input>
    </el-form-item>
    <el-form-item label="whs_id" prop="whs_id">
      <el-input v-model="editingItem.whs_id"></el-input>
    </el-form-item>
    <el-form-item label="机械码" prop="machine_id">
      <el-input v-model="editingItem.machine_id"></el-input>
    </el-form-item>
    <el-form-item label="状态" prop="item_status">
      <el-select v-model="editingItem.item_status" placeholder="选择状态">
        <el-option label="已退回" value="已退回"></el-option>
      </el-select>
    </el-form-item>
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="showEditModel = false">取消</el-button>
    <el-button type="primary" @click="updateScannedItem">保存</el-button>
  </span>
</el-dialog>
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="page"
    :page-sizes="[10, 20, 50, 100]"
    :page-size="limit"
    layout="total, sizes, prev, pager, next, jumper"
    :total="total">
  </el-pagination>

  </div>
</template>

<script>
  // import axios from 'axios';
  import instance from '@/utils/axios.config';
  import { searchScannedItems, searchScannableItems, updateScannedItems, deleteScannedItems, insertScannedItems} from '@/utils/scanned_items.js'
  import { formatLocalDate, convertToMelbourneTime } from '@/utils/time_utils';
  import { getRole } from '@/utils/auth';
      
  export default{
    name:"ScannedItemsTable",
    data() {
      return {
        showInsertModel: false,
        searchTrackingId: [],
        newItem: {
            user_id: null,
            tracking_id: null,
            height: null,
            width: null,
            length: null,
            volume: null,
            weight: null,
            whs_id: null,
            machine_id: null,
            item_status: null,
        },
        rules: {
            height: [
                { required: true, message: '高度不能为空', trigger: 'blur' },
                { validator: this.checkNumber, trigger: 'blur' }
            ],
            width: [
                { required: true, message: '宽度不能为空', trigger: 'blur' },
                { validator: this.checkNumber, trigger: 'blur' }
            ],
            length: [
                { required: true, message: '长度不能为空', trigger: 'blur' },
                { validator: this.checkNumber, trigger: 'blur' }
            ],
            volume: [
                { required: true, message: '体积不能为空', trigger: 'blur' },
                { validator: this.checkNumber, trigger: 'blur' }
            ],
            weight: [
                { required: true, message: '重量不能为空', trigger: 'blur' },
                { validator: this.checkNumber, trigger: 'blur' }
            ],
            item_status: [
                { required: true, message: '状态必须选择', trigger: 'change' }
            ]
        },       
        showEditModel: false,
        editingItem: {
            user_id: null,
            tracking_id: null,
            height: 1.000,
            width: 1.000,
            length: 1.000,
            volume: null,
            weight: null,
            whs_id: null,
            machine_id: null,
            item_status: null,
        },
        search: {
                user_id: null,
                item_status: null,
                tracking_id: null,
                item_id: null,
                scanned_from: null,
                scanned_to: null,
                is_sensitive_good: null,
                is_online: null,
                is_pickup_required: null
        },
        newItemSearch: {
                user_id: null,
                tracking_id: null
        },
        originalDatatable: [],
        scannableItems: [],
        limit: 10,
        page: 1,
        total: 0,
        role: null,
      };
    },
      computed: {
        filteredDatatable() {
            let result = this.originalDatatable;
            
            Object.keys(this.search).forEach(key => {
              if (this.search[key] && typeof this.search[key] === 'string') {
                  let searchString = this.search[key].replace('*', '.*');
                  let regex = new RegExp('^' + searchString, 'i');
                  result = result.filter(order => regex.test(String(order[key])));
              }
              else if (typeof this.search[key] === 'boolean') {
                // Filter based on the checkbox value
                result = result.filter(order => order[key] === this.search[key]);
            }
          });
            
            if (this.search.scanned_from || this.search.scanned_to) {
     
      
                result = result.filter(item => {
                    let scanned_from = this.search.scanned_from ? convertToMelbourneTime(this.search.scanned_from) : null;
                    console.log("scanned_from", scanned_from);
                    let scanned_to = this.search.scanned_to ? convertToMelbourneTime(this.search.scanned_to) : null;
                    console.log("scanned_to", scanned_to);
                    let itemCreateFrom = convertToMelbourneTime(item.created_at);
                    let itemCreateTo = convertToMelbourneTime(item.created_at);

                    if (scanned_from && itemCreateFrom < scanned_from) return false;
                    if (scanned_to && itemCreateTo > scanned_to) return false;
                    return true;
                });
            }
            return result;
        }
      },
      watch: {
        search: {
          handler() {
            // 检查 search 对象的所有属性是否都为空
            const isEmpty = Object.values(this.search).every(value => value === '');
            if (isEmpty) {
              this.loadData(); // 如果所有属性都为空，调用 getload 方法
            }
          },
          deep: true // 深度观察 search 对象的所有属性
        },
        newItemSearch: {
          handler() {
            // 检查 newItemSearch 对象的所有属性是否都为空
            const isEmpty = Object.values(this.newItemSearch).every(value => value === '');
            if (isEmpty) {
              this.scannableItems = []; // 如果所有属性都为空，清空 scannableItems 数组
            }
          },
          deep: true // 深度观察 newItemSearch 对象的所有属性
        }
      },
      methods:{
          formatLocalDate,
          getRole,
          loadData() {
            instance.get(`/items/scanned_items/items/all`, {
              headers: {
                'Content-Type': 'application/json'
              },
              params: {
                page: this.page,
                limit: this.limit
              }
            })
            .then(response => {
              this.originalDatatable = response.data.data;
              this.total = response.data.data.length === 0 ? 0 : parseInt(response.data.data[0].number_of_rows);
            })
            .catch(error => {
              console.error("加载失败", error);
            });
          },         

          // 搜索可入库物品
          async searchScannableItem(){
            let newItemSearch = JSON.parse(JSON.stringify(this.newItemSearch));
              // 检查所有必需字段是否已填写
              if (!newItemSearch.user_id && !newItemSearch.tracking_id) {
                this.$message({
                  message: 'user_id 或 tracking_id 为必填项',
                  type: 'error'
                });
                return;
              }
              //  发送搜索请求
              try {
                const response = await searchScannableItems({
                  user_id: newItemSearch.user_id, 
                  tracking_id: newItemSearch.tracking_id
                });

                if (response.status === 200) {
                  console.log("搜索成功", response.data);
                  this.scannableItems = response.data;
                  this.$message.success('搜索成功, 请从下拉框中选择可入库物品');
                }
              } catch (error) {
                this.$message.error(`获取物品失败! ${error.message}`);
              }

          },
          // 普通搜索
          async searchScannedItem() {
            // make a copy of the search input
            let searchInput = JSON.parse(JSON.stringify(this.search));
            console.log("searchInput", searchInput);
            try {
              const response = await searchScannedItems({
                user_id: searchInput.user_id, 
                tracking_id: searchInput.tracking_id,
                item_status: searchInput.item_status,
                item_id: searchInput.item_id,
                scanned_from: searchInput.scanned_from,
                scanned_to: searchInput.scanned_to,
                is_sensitive_good: searchInput.is_sensitive_good,
                limit: this.limit,
                page: this.page
              });
              if (response.status === 200) {
                this.$message.success(`搜索成功, 返回 ${response.data.length} 条结果`)
                this.originalDatatable = response.data;
                this.total = response.data.length === 0 ? 0 : response.data.length;
              }
            } catch (error) {
              this.$message.error(`搜索失败, ${error.message}`)
            }
          },
          clearSearchResult() {
                this.search = {
                    user_id: null,
                    item_status: null,
                    tracking_id: null,
                    item_id: null,
                    scanned_from: null,
                    scanned_to: null,
                    is_sensitive_good: null,
                    is_pickup_required: null,
                };
                this.page = 1;
                this.loadData();
          },
          clearEditItem() {
            this.editingItem = {
                user_id: null,
                tracking_id: null,
                height: null,
                width: null,
                length: null,
                volume: null,
                weight: null,
                whs_id: null,
                machine_id: null,
                item_status: null,
            },
              this.loadData();
          },

          async insertNewItem() {
                    // 验证表单
            this.$refs.newItemForm.validate(async (valid) => {
              if (!valid) {
                  this.$message.error('请检查输入后重试');
                  return;
              }
              // 复制一份 newItem 对象
              let item = JSON.parse(JSON.stringify(this.newItem));
              // 插入搜索的 tracking_id, 和 user_id
              item.tracking_id = this.newItemSearch.tracking_id || this.newItem.tracking_id;
              item.user_id = parseInt(this.newItemSearch.user_id);
              // 检查所有必需字段是否已填写
              if (!item.tracking_id || this.newItemSearch.tracking_id) {
                this.$message.error("请选择一个物品");
                return;
              }
              // 准备请求体
              const body = {
                tracking_id: item.tracking_id, 
                user_id: item.user_id,
                weight: item.weight,
                length: item.length,
                width: item.width,
                height: item.height,
                volume: item.volume,
                whs_id: item.whs_id,
                machine_id: item.machine_id,
                item_status: item.item_status
              };
              // 发送请求
              try {
                // const response = await instance.post(`/items/scanned_items/insert`, body, {
                //   headers: {
                //     'Content-Type': 'application/json',
                //   }
                // });
                await insertScannedItems({itemData: body});
                this.$message.success("入库成功");
                // 清空编辑项
                this.closeInsertModel();
                // 重新加载数据等其他操作
                this.loadData(); 

              } catch (error) {
                console.error("入库失败", error);
                this.$message.error(`入库失败:  ${error.message}`);
              }
            });
          },

          async updateScannedItem() {
            this.$refs.editingItemForm.validate(async (valid) => {
              if (!valid) {
                  this.$message.error('请检查输入后重试');
                  return;
              }            
              try {
                await updateScannedItems({ itemId: this.editingItem.item_id, itemData: this.editingItem});
                this.showEditModel = false;
                // 清空 editingItem
                this.clearEditItem();
                this.loadData();  
                this.$message.success("更新物品成功");
              } catch (error) {
                this.$message.error(`更新失败, ${error.message}`);

              }
            });
          },
          editScannedItem(item) {
            // 存储要编辑的物品信息
            this.editingItem = JSON.parse(JSON.stringify(item));
            // 打开模态窗口或导航到编辑页面
            this.showEditModel = true;
          },
          async deleteScannedItem(itemId) {
            this.$confirm('确定要删除这个物品吗?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(async () => {
              try {
                await deleteScannedItems(itemId);
                this.$message.success(`删除物品${itemId}成功`);
                this.loadData(); // 重新加载数据
              } catch (error) {
                this.$message.error(`${error.message}`);
              }
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '取消删除'
              });      
            });
          },

          handleSizeChange(val) {
                this.limit = val;
                this.page = 1; // Reset to first page when page size changes
                this.loadData();
            },
            handleCurrentChange(val) {
                this.page = val;
                this.loadData();
            },

          closeInsertModel() {
            this.showInsertModel = false;
            this.newItem = {};
            this.newItemSearch = {};
          },

          checkNumber(rule, value, callback) {
            if (isNaN(value)) {
                callback(new Error('物品申报价值必须为数字'));
            } else if (value <= 0) {
                callback(new Error('物品申报价值必须为大于0的数字'));
            } else {
                callback();
            }
          }

        },
        beforeMount() {
          this.loadData();
      },

      created(){
          // Get role from localStorage when the component is created
          const { role, currentUser } = getRole();
          this.role = role;
          console.log("role", role);
          this.currentUser = currentUser;
      },

  }

</script>

<style scoped>
.search-row {
    display: flex;
    flex-wrap: nowrap; /* 阻止元素换行 */
    align-items: center;
    gap: 10px; /* 为元素添加间隙 */
    margin-bottom: 20px;
    max-width: 100%; /* 限制宽度为屏幕的一半 */
}

.el-select, .date-picker-wide {
      width: 100%; /* 限制最大宽度 */
}

.item-status-select {
  width: 2000px; /* 限制最大宽度 */
}

.table-container {
  margin-top: 20px;
  overflow-x: auto; /* 显示横向滚动条 */
  overflow-y: auto; 
}

.el-table-column {
  text-align: center;
}

.el-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .search-row {
    flex-direction: column;
  }

  .el-input, .el-select, .el-date-picker {
    max-width: none;
  }
}
</style>